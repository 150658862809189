<template>
  <!-- 提现管理页面 -->
  <div class="container">
    <a-row class="right-content-top" :gutter="10" style="margin-bottom: 10px">
      <a-col :span="8" class="header-info">
        <div class="bg-info">
          <img src="@/assets/icons/icon_01.png" alt="" />
          <div class="icon-wrapper">
            <h1>{{ topInfoList.withdrawalAmountTotal }}</h1>
            <span>总金额</span>
          </div>
        </div>
      </a-col>
      <a-col :span="8" class="header-info">
        <div class="bg-info">
          <img src="@/assets/icons/icon_02.png" alt="" />
          <div class="icon-wrapper">
            <h1>{{ topInfoList.serviceChargeTotal }}</h1>
            <span>手续费</span>
          </div>
        </div>
      </a-col>
      <a-col :span="8" class="header-info">
        <div class="bg-info">
          <img src="@/assets/icons/icon_04.png" alt="" />
          <div class="icon-wrapper">
            <h1>{{ topInfoList.amountTotal }}</h1>
            <span>转账金额</span>
          </div>
        </div>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="24" class="right">
        <div class="right-content">
          <!-- 筛选 -->
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="40">
                <!-- 申请人姓名 -->
                <a-col :md="8" :sm="24">
                  <a-form-item label="关键词">
                    <a-input v-model="queryParam.keyword" allow-clear placeholder="关键词搜索" />
                  </a-form-item>
                </a-col>
                <!-- 审核状态 -->
                <a-col :md="8" :sm="24">
                  <a-form-item label="用户类型">
                    <a-select v-model="queryParam.userType" allow-clear placeholder="请选择">
                      <a-select-option :value="1">用户</a-select-option>
                      <a-select-option :value="2">商家</a-select-option>
                      <a-select-option :value="4">代理商</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <!-- 审核状态 -->
                <a-col :md="8" :sm="24">
                  <a-form-item label="审核状态">
                    <a-select v-model="queryParam.status" allow-clear placeholder="请选择">
                      <a-select-option v-for="(item, index) in resultList" :key="index" :value="item.status">
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :md="8" :sm="24">
                  <a-form-item label="提现类型">
                    <a-select v-model="queryParam.positions" allow-clear placeholder="请选择">
                      <a-select-option :value="1">订单收益</a-select-option>
                      <a-select-option :value="2">佣金（用户）</a-select-option>
                      <a-select-option :value="4">佣金（代理商）</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <!-- 时间 -->
                <a-col :md="8" :sm="24">
                  <a-form-item label="时间">
                    <a-range-picker allow-clear v-model="queryParam.time" show-time style="width: 320px" />
                  </a-form-item>
                </a-col>
                <!-- 搜索和重置 -->
                <a-col :md="8" :sm="24">
                  <a-button type="primary" @click="searchTableData">搜索</a-button>
                  <a-button style="margin-left: 8px" @click="() => (queryParam = {})">重置</a-button>
                  <a-button type="primary" @click="exportDataList" style="margin-left: 8px">导出</a-button>
                </a-col>
              </a-row>
            </a-form>
          </div>
          <!-- 表格 -->
          <a-table
            :columns="columns"
            :data-source="data"
            :scroll="{ x: 1300 }"
            :loading="loading"
            :pagination="false"
            bordered
          >
            <!-- 用户备注插槽 -->
            <span slot="remarks" slot-scope="text" class="font-line">
              {{ text ? text : '' }}
            </span>
            <!-- 用户类型插槽 -->
            <span slot="userType" slot-scope="text">
              {{ text == 1 ? '用户' : text == 2 ? '商家' : text == 4 ? '代理商' : '' }}
            </span>
            <!-- 来源插槽 -->
            <span slot="position" slot-scope="text">
              {{
                text == 1
                  ? '订单收益'
                  : text == 2
                    ? '佣金（用户）'
                    : text == 3
                      ? '礼物收益'
                      : text == 4
                        ? '佣金（代理商）'
                        : ''
              }}
            </span>
            <!-- 审核状态插槽 -->
            <span slot="checkStatus" slot-scope="text">
              <a-tag
                style="line-height: 20px"
                v-show="text"
                :color="text == 1 ? 'orange' : text == 2 ? 'green' : text == 3 ? 'red' : ''"
              >
                {{ text == 1 ? '待审核' : text == 2 ? '已通过' : text == 3 ? '已拒绝' : '' }}
              </a-tag>
            </span>
            <span slot="transferVoucher" slot-scope="text">
              <viewer v-if="text">
                <img :src="text" style="width: 48px; height: 48px" alt="" />
              </viewer>
            </span>
            <span slot="invoiceImage" slot-scope="text">
              <viewer v-if="text">
                <img :src="text" style="width: 48px; height: 48px" alt="" />
              </viewer>
            </span>
            <!-- 操作插槽 -->
            <span slot="action" slot-scope="text, record">
              <a @click="$refs.detailForm.detail(record)">详情</a>
              <span v-show="record.status == 1">
                <a-divider type="vertical" />
                <a @click="$refs.auditForm.audit({ id: record.id, status: '2' })">通过</a>
                <a-divider type="vertical" />
                <a @click="$refs.auditForm.audit({ id: record.id, status: '3' })">拒绝</a>
              </span>
            </span>
          </a-table>
          <!-- 分页组件 -->
          <div style="margin-top: 20px; display: flex; flex-direction: row-reverse">
            <a-pagination
              :pageSize="page.pageSize"
              :current="page.pageNo"
              show-size-changer
              :page-size-options="['10', '20', '50', '100']"
              show-quick-jumper
              :total="total"
              :show-total="(total) => `共 ${total} 条`"
              @change="pageChange"
              @showSizeChange="sizeChange"
            >
            </a-pagination>
          </div>
        </div>
      </a-col>
    </a-row>
    <!-- 使用子组件 -->
    <detail-form ref="detailForm"></detail-form>
    <audit-form ref="auditForm" @ok="getList"></audit-form>
  </div>
</template>

<script>
import { tixianAdminPageList, getWithdrawalTotalNum } from '@/api/modular/mallLiving/tixianAdmin'

import detailForm from './detailForm.vue'
import auditForm from './auditForm.vue'

const columns = [
  {
    title: '序号',
    align: 'left',
    width: '80px',
    key: 'number',
    customRender: (text, record, index) => `${index + 1}`,
  },
  {
    title: '用户类型',
    dataIndex: 'userType',
    width: '80px',
    key: 'userType',
    align: 'left',
    scopedSlots: {
      customRender: 'userType',
    },
  },
  {
    title: '昵称',
    dataIndex: 'nickname',
    width: '120px',
    key: 'nickname',
    align: 'left',
  },
  {
    title: 'ID号',
    width: '120px',
    dataIndex: 'idNo',
    align: 'center',
  },

  {
    title: '提现方式',
    align: 'left',
    width: '120px',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '提现类型',
    dataIndex: 'position',
    width: '120px',
    key: 'position',
    align: 'center',
    scopedSlots: {
      customRender: 'position',
    },
  },
  {
    title: '提现金额',
    width: '100px',
    dataIndex: 'withdrawalAmount',
    key: 'withdrawalAmount',
    align: 'left',
  },
  {
    title: '提现账号',
    width: '160px',
    dataIndex: 'cashAccount',
    align: 'center',
  },
  {
    title: '到账金额',
    width: '100px',
    dataIndex: 'amount',
    align: 'left',
  },
  {
    title: '联系人',
    width: '120px',
    dataIndex: 'linkman',
    align: 'center',
  },
  {
    title: '联系方式',
    width: '120px',
    dataIndex: 'contactWay',
    align: 'center',
  },
  {
    title: '手续费',
    dataIndex: 'serviceCharge',
    width: '80px',
    key: 'serviceCharge',
    align: 'center',
  },
  {
    title: '余额',
    width: '120px',
    align: 'center',
    dataIndex: 'balance',
  },
  {
    title: '用户备注',
    dataIndex: 'remarks',
    key: 'remarks',
    width: '150px',
    align: 'center',
    scopedSlots: {
      customRender: 'remarks',
    },
  },
  {
    title: '发票',
    dataIndex: 'invoice',
    width: '120px',
    key: 'invoice',
    align: 'center',
    scopedSlots: {
      customRender: 'invoiceImage',
    },
  },
  {
    title: '转账凭证',
    dataIndex: 'transferVoucher',
    width: '120px',
    key: 'transferVoucher',
    align: 'center',
    scopedSlots: {
      customRender: 'transferVoucher',
    },
  },
  {
    title: '审核状态',
    dataIndex: 'status',
    width: '120px',
    key: 'status',
    align: 'center',
    scopedSlots: {
      customRender: 'checkStatus',
    },
  },
  {
    title: '申请时间',
    dataIndex: 'createTime',
    width: '180px',
    key: 'createTime',
    align: 'center',
  },
  {
    title: '操作',
    dataIndex: 'action',
    width: '170px',
    fixed: 'right',
    scopedSlots: {
      customRender: 'action',
    },
  },
]

export default {
  data() {
    return {
      columns,
      data: [],
      topInfoList: {},
      page: {
        pageNo: 2,
        pageSize: 10,
      },
      total: 0,
      loading: false,
      checkStatus: '1',
      selectedRowKeys: [], //表格行的选中项数组
      selectRowId: [], //选中行的id数组
      queryParam: {
        position: [1, 2, 4],
        isExport: 1,
      },
      resultList: [
        {
          status: 1,
          name: '待审核',
        },
        {
          status: 2,
          name: '已通过',
        },
        {
          status: 3,
          name: '已拒绝',
        },
      ],
      enterMainTypeList: [
        {
          status: 1,
          name: '个体',
        },
        {
          status: 2,
          name: '个体工商户',
        },
        {
          status: 3,
          name: '公司企业',
        },
      ],
    }
  },
  components: {
    detailForm,
    auditForm,
  },
  mounted() {
    this.initTableData()
  },
  methods: {
    exportDataList() {
      if (this.queryParam.time && this.queryParam.time.length == 2) {
        this.queryParam.startTime = this.onChange(this.queryParam.time[0]._d)
        this.queryParam.endTime = this.onChange(this.queryParam.time[1]._d)
      } else {
        this.queryParam.startTime = ''
        this.queryParam.endTime = ''
      }
      if (this.queryParam.positions) {
        let position_s = []
        position_s.push(this.queryParam.positions)
        this.queryParam.position = position_s
      } else {
        let position_s = [1, 2, 4]
        this.queryParam.position = position_s
      }
      this.queryParam.isExport = 2
      this.axios
        .post(`/withdrawalRecord/page`, this.queryParam, {
          responseType: 'blob', //服务器响应的数据类型，可以是 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream'，默认是'json'
        })
        .then((res) => {
          if (!res) return
          const blob = new Blob([res.data], {
            type: 'application/vnd.ms-excel',
          }) // 构造一个blob对象来处理数据，并设置文件类型

          const href = URL.createObjectURL(blob) //创建新的URL表示指定的blob对象
          const a = document.createElement('a') //创建a标签
          a.style.display = 'none'
          a.href = href // 指定下载链接
          a.download = '提现记录_' + new Date().getTime() + '.xlsx' //指定下载文件名
          a.click() //触发下载
          URL.revokeObjectURL(a.href) //释放URL对象
        })
        .catch((err) => {
          console.log(err)
        })
    },
    pageChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    sizeChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    searchTableData() {
      this.initTableData()
    },
    initTableData() {
      this.page.pageNo = 1
      this.getList()
    },
    onChange(date) {
      var date = new Date(date).getTime()
      // var year = date.getFullYear()
      // var month = date.getMonth() + 1
      // var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      return date
    },
    getList() {
      this.loading = true
      if (this.queryParam.time && this.queryParam.time.length == 2) {
        this.queryParam.startTime = this.onChange(this.queryParam.time[0]._d)
        this.queryParam.endTime = this.onChange(this.queryParam.time[1]._d)
      } else {
        this.queryParam.startTime = ''
        this.queryParam.endTime = ''
      }
      if (this.queryParam.positions) {
        let position_s = []
        position_s.push(this.queryParam.positions)
        this.queryParam.position = position_s
      } else {
        let position_s = [1, 2, 4]
        this.queryParam.position = position_s
      }
      this.queryParam.isExport = 1
      let query = {
        queryParam: this.queryParam,
        page: this.page,
      }
      tixianAdminPageList(query)
        .then((res) => {
          if (res.success) {
            this.data = res.data.rows
            this.total = res.data.totalRows
            this.loading = false
          }
        })
        .finally((res) => {
          setTimeout(() => {
            this.loading = false
          }, 5000)
        })
      getWithdrawalTotalNum(this.queryParam).then((res) => {
        if (res.success) {
          this.topInfoList = res.data
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.container {
  min-width: 1000px;
}

.title {
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  font-weight: bold;
}

.right {
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 100);
}

.right-content {
  box-sizing: border-box;
  padding: 20px;
}

.icon {
  font-size: 20px;
  margin: 0 5px;
}

img {
  width: 20px;
  height: 20px;
}

.font-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.header-info {
  .bg-info {
    background: rgba(255, 255, 255, 100);
    padding: 5px;

    img {
      width: 95px;
      height: 107px;
    }

    .icon-wrapper {
      display: inline-block;
      vertical-align: middle;
      margin-left: 10px;

      h1 {
        font-size: 23px;
        line-height: 25px;
      }
    }
  }
}
</style>
